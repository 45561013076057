import moment, * as moments from "moment";
import momentTimezone from "moment-timezone";
import "moment/min/locales";

const DATE_TIME_FORMAT = {
  FORMAT: "YYYY-MM-DD",
  LONG_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  US_DATE_FORMAT: "MM/DD/YYYY",
  DATE_FORMAT: "MM-DD-YYYY",
  YEAR: "YYYY",
};

const timeZone = "America/Chicago";

function dateCompare(date1, date2) {
  date1 = moment(date1, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss");
  date2 = moment(date2, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss");
  return moment(date1).unix() - moment(date2).unix();
}

function formatDate(date, format = "MM/DD/YYYY") {
  if (!date) {
    return null;
  }
  const offset = momentTimezone(date).tz(timeZone).format("Z");
  return momentTimezone.utc(date).utcOffset(offset).format(format);
}

function dateNow(format = DATE_TIME_FORMAT.LONG_DATE_FORMAT) {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime.utc().format(format);
}

function getDateFromObject(dateObj, format = "") {
  return moment(dateObj).utc().format(format);
}

function getDateFromObjectUsingTimezone(dateObj, format) {
  const cstDateTime = momentTimezone.tz(dateObj, timeZone);
  return moment(cstDateTime).utc().format(format);
}
function getObjectFromStringDate(dateString) {
  return moment(dateString);
}
function getObjectFromStringDateUsingTimezone(dateString) {
  if (!dateString) {
    return null;
  }
  const offset = momentTimezone(dateString).tz(timeZone).format("Z");
  return momentTimezone.utc(dateString).utcOffset(offset);
}
function getDateFromString(string, format = "") {
  return moment(string).format(format);
}
function changeFormat(
  string,
  requiredFormat = "",
  format = DATE_TIME_FORMAT.LONG_DATE_FORMAT
) {
  return moment(string, format).format(requiredFormat);
}
function getPreviousMonthDate(previousMonth) {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(previousMonth, "month")
    .endOf("month")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getPreviousMonthLastDate(previousMonth) {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(previousMonth, "month")
    .startOf("month")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getPreviousYearDate(previousMonth) {
  return moment()
    .subtract(previousMonth, "year")
    .endOf("year")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getPreviousYearFirstDate(previousMonth) {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(previousMonth, "year")
    .startOf("year")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getPreviousYearLastDate(previousMonth) {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(previousMonth, "year")
    .endOf("year")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getStartDayOfMonth() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime.startOf("month").format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getStartDayofYear() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime.startOf("year").format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getStartDayofWeek() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .startOf("week")
    .add(1, "day")
    .utc()
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}
function getStartDayofLastWeek() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .startOf("week")
    .subtract(6, "day")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}
function getEndDayofLastWeek() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime.startOf("week").format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getEndDayOfMonth() {
  return moment().endOf("month").format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function isFutureDate(data) {
  return moment().isBefore(data);
}

function timeStampNow() {
  return moment().valueOf();
}

function getDate(num, type) {
  return moment()
    .subtract(num, type)
    .utc()
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getFutureDate(num, type) {
  return moment().add(num, type);
}

function getFirstQuaterDate() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .startOf("quarter")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getQuaterRange() {
  return moment().quarter();
}

function getPreviousQuaterRange() {
  const quarter = moment().quarter();
  return quarter === 1 ? 4 : quarter - 1;
}

function getPreviousQuaterStartDate() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(1, "quarter")
    .startOf("quarter")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getPreviousQuaterEndDate() {
  const cstDateTime = momentTimezone.tz(moment(), timeZone);
  return cstDateTime
    .subtract(1, "quarter")
    .endOf("quarter")
    .format(DATE_TIME_FORMAT.LONG_DATE_FORMAT);
}

function getMonthFromDate(date, format) {
  let dateFormat = moment(date, DATE_TIME_FORMAT.LONG_DATE_FORMAT);
  return dateFormat.format(format);
}

function getMonthQuaters(qtr) {
  if (qtr === 1) {
    return [1, 2, 3];
  } else if (qtr === 2) {
    return [4, 5, 6];
  } else if (qtr === 3) {
    return [7, 8, 9];
  } else if (qtr === 4) {
    return [10, 11, 12];
  }
}

function getAllMonthsName() {
  return moment.monthsShort();
}

function getAllWeekName() {
  return moment.weekdaysShort();
}

function getCurrentMonth() {
  return moment().month() + 1;
}

function getDateByDayName(date) {
  return moment(date).format("ddd");
}

function getMonthNameByNumber(num) {
  return moment().month(num).format("MMM");
}

function getMinAgeByYear(num, year) {
  return moment().subtract(num, year);
}

function getDayEndDate() {
  return moment().endOf("day");
}

function getEndOfYearDate() {
  return moment().endOf("year");
}

function changeLocale(lng) {
  moment.locale(lng);
}

const dateTimeService = {
  dateNow,
  getPreviousMonthDate,
  getDateFromObject,
  isFutureDate,
  getDateFromString,
  DATE_TIME_FORMAT,
  timeStampNow,
  getDate,
  getStartDayOfMonth,
  getEndDayOfMonth,
  getPreviousMonthLastDate,
  getPreviousYearDate,
  getPreviousYearFirstDate,
  getPreviousYearLastDate,
  getStartDayofYear,
  getFirstQuaterDate,
  getQuaterRange,
  getPreviousQuaterStartDate,
  getPreviousQuaterEndDate,
  getMonthFromDate,
  getMonthQuaters,
  getAllMonthsName,
  getPreviousQuaterRange,
  getAllWeekName,
  getCurrentMonth,
  getDateByDayName,
  getMonthNameByNumber,
  getStartDayofWeek,
  getStartDayofLastWeek,
  getEndDayofLastWeek,
  changeFormat,
  getObjectFromStringDate,
  getDateFromObjectUsingTimezone,
  getDayEndDate,
  getMinAgeByYear,
  dateCompare,
  formatDate,
  getObjectFromStringDateUsingTimezone,
  changeLocale,
  getFutureDate,
  getEndOfYearDate,
};

export default dateTimeService;
