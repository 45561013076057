import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import SettingsReducer from "../slices/Settings";
import CommonReducer from "../slices/Common";
import SearchReducer from "../slices/GlobalSearch";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: SettingsReducer,
        common: CommonReducer,
        search: SearchReducer,
    });

export default createRootReducer;
