import React from "react";
import { Spin } from "antd";

function FullPageLoader() {
    return (
        <React.Fragment>
            <div className="loader loading">
                <Spin /> Loading...
            </div>
        </React.Fragment>
    );
}

export default FullPageLoader;
