import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import FullPageLoader from "./components/full-page-loader";
import Routes from "./routes";

const store = configureStore();

const NextApp = () => (
    <React.Suspense fallback={<FullPageLoader />}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Routes />
            </ConnectedRouter>
        </Provider>
    </React.Suspense>
);

export default NextApp;
