import { createSlice } from "@reduxjs/toolkit";

export const SEARCH_SCOPES = {
    GLOBAL: "global",
    EVENT: "event",
    SERVICE: "service",
    VENDOR: "vendor",
    USER: "user",
    BOOKING: "booking",
    PURCHASE_TICKETS: "tickets",
    MY_EARNINGS: "my_earnings",
    EventTickets: "event_tickets",
};
const initState = {
    search: "",
    data: null,
    scope: "",
    loading: false,
};
const globalSearchSlice = createSlice({
    name: "common",
    initialState: initState,
    reducers: {
        setInitData: () => initState,
        setCurrentScope: (state, action) => {
            state.scope = action.payload;
        },
        onSearchRequestComplete: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        onSearchChange: (state, action) => {
            state.search = action.payload;
            if (action.payload === "") state.data = null;
        },
        onSearchRequestStart: (state) => ({ ...state, loading: true }),
    },
});

export const {
    onSearchRequestComplete,
    onSearchChange,
    onSearchRequestStart,
    setCurrentScope,
    setInitData,
} = globalSearchSlice.actions;
export default globalSearchSlice.reducer;
